import React from "react";
import { motion } from "framer-motion"
import { Link } from "react-router-dom";

import "./Footer.scss";

import logo from "../../images/logo.png";
import AgreementModal from "../AgreementModal";

export default function Footer(){

    let footerRef= React.useRef();
    let footerContainerRef= React.useRef();
    let [agreementActive, setAgreementActive] = React.useState(false);

    let closeAgreement = () => {
        setAgreementActive(false);
        document.body.setAttribute("overflow", "visible")
    }

    React.useEffect(() => {
        let observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if(entry.isIntersecting === true) {
                    let classList = entry.target.className;
                    let newClassName = classList.replaceAll(" active", "");
                    entry.target.className = newClassName + " active";
                    observer.unobserve(entry.target);
                }
            })  
        }, {threshold: 0.3})
    
        observer.observe(footerContainerRef.current);
        observer.observe(footerRef.current);
    }, [])

    return(
        <div className="footer box" ref={footerRef}>
            <div className="container" ref={footerContainerRef}>
                <div className="box-gradient"></div>
                <div className="footer-top">
                    <div className="footer-left">
                        <div className="footer-title"><img src={logo} alt="" />Radiance</div>
                        <p>In any game with any cheat, it is possible to get your account blocked. Play as carefully as possible.</p>
                    </div>
                    <div className="footer-right">
                        <ul className="footer-right-ul">
                            <li className="footer-ul-title">Navigation</li>
                            <Link to="/"><li>Home</li></Link>
                            <a href="https://discord.com/invite/radianceproject/" target="_blank"><li>Support</li></a>
                            <motion.a onClick={(e) => {
                                e.preventDefault();
                                document.body.setAttribute("overflow", "hidden")
                                setAgreementActive(!agreementActive);
                            }}>Privacy Policy</motion.a>
                            <Link to="/faq"><li>FAQ</li></Link>
                        </ul>
                        <ul className="footer-right-ul">
                            <li className="footer-ul-title">Our Products</li>
                            <Link to="/rust-internal"><li>Rust Internal</li></Link>
                            <Link to="/rust-external"><li>Rust External</li></Link>
                            <a><li>Coming Soon...</li></a>
                        </ul>
                        <ul className="footer-right-ul">
                            <li className="footer-ul-title">Links</li>
                            <div className="footer-links">
                                <a href="https://www.youtube.com/@radianceprojectxyz/" target="_blank">
                                    <span>Youtube</span>
                                </a>
                                <Link to={"/discord"} target="_blank">
                                    <span>Discord</span>
                                </Link>
                                <a href="https://www.elitepvpers.com/forum/rust-trading/5270025-radiance-ud-rust-external-aimbot-esp-misc-more.html#post40152788" target="_blank">
                                    <span>Elitepvpers</span>
                                </a>
                            </div>
                        </ul>
                    </div>
                </div>
                <div className="footer-bottom">
                    <span>2025 © Radiance - Copying and distribution of materials is prohibited.</span>
                </div>

                <AgreementModal agreementActive={agreementActive} closeAgreement={closeAgreement} setCheckboxActive={() => {}} />
            </div>
        </div>
    )
}