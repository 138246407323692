import React from "react";

import SlideInAnimation from "../Components/SlideInAnimation";
import RustExternalLoader from "../RustExternalLoader/Download";

export default function RustExternalLoaderPage(){
    return (
        <>
            <RustExternalLoader />
            <SlideInAnimation />
        </>
    )
}