import React from "react";

// import { motion, AnimatePresence } from "framer-motion"
import { Link } from "react-router-dom";
import loader from "../loaders/rust-external.rar"

import "./RustExternalLoader.scss"

import logo from "../images/RadianceLogo.webp"
import alert_square from "../images/faq-alert-square.svg"
import loader_cheat_functionality from "../images/loader-cheat-functionality.svg"
import loader_cheat_instructions from "../images/loader-cheat-instructions.svg"
import loader_support from "../images/loader-support.svg"
import loader_download from "../images/loader-download.svg"


export default function RustExternalLoader(){

    let rust_loader_ref = React.useRef();

    function download(url, filename){
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();

        // window.location.href = "about:blank"
    }

    const [fileSize, setFileSize] = React.useState("")

    React.useEffect(() => {
        async function getFileSize(url) {
            try {
                const response = await fetch(url, { 
                    method: "HEAD",
                    headers: {
                        "Accept-Encoding": "identity", // Prevents compression
                        "Cache-Control": "no-cache", // Avoid cached responses
                    }
                });
                const contentLength = response.headers.get("content-length");
                setFileSize((contentLength / 1024 / 1024).toFixed(2))
            } catch (error) {
                setFileSize("0.00")
            }
          }
          // Example usage:
          getFileSize(loader + `?nocache=${Date.now()}`);
    }, [])

    React.useEffect(() => {
        let observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if(entry.isIntersecting === true) {
                    let classList = entry.target.className;
                    let newClassName = classList.replaceAll(" active", "");
                    entry.target.className = newClassName + " active";
                    observer.unobserve(entry.target);
                }
            })  
        }, {threshold: 0.3})
        
        observer.observe(rust_loader_ref.current)
    }, [])

    return (
        <>
            <div className="rust_loader box">
                <div className="container" ref={rust_loader_ref}>
                    <div className="box-gradient"></div>
                    <div className="rust_loader_title">
                        <h1 className="rust_loader_main">Download Launcher</h1>
                        <p className="rust_loader_sub">
                            <span>Downloading Radiance ensures a fast, secure experience,<br />granting users exclusive access to top-tier cheat functionalities.</span>
                        </p>
                    </div>
                    <div className="rust_loader_instructions_section">
                        <div className="rust_loader_inctructions_header" data-id="0">
                            <div className="rust_loader_inctructions_title">
                                <div><img src={logo} alt="" /></div>
                                <span>Radiance Rust External</span></div>
                            <p><img src={alert_square} alt="" /> All the important information you might need:</p>
                        </div>
                        <div className="rust_loader_instructions_list">
                            <div className="rust_loader_instructions_item">
                                <div className="rust_loader_instructions_item_left">
                                    <div className="rust_loader_instructions_item_icon">
                                        <img src={loader_cheat_functionality} alt="" />
                                    </div>
                                    <div className="rust_loader_instructions_item_title">
                                        <p>System requirements and functionality.</p>
                                        <span>View cheat info!</span>
                                    </div>
                                </div>
                                <div className="rust_loader_instructions_item_right">
                                    <Link className="rust_loader_instructions_item_button" to="/rust-external">
                                        View
                                    </Link>
                                </div>
                            </div>
                            <div className="rust_loader_instructions_item">
                                <div className="rust_loader_instructions_item_left">
                                    <div className="rust_loader_instructions_item_icon">
                                        <img src={loader_cheat_instructions} alt="" />
                                    </div>
                                    <div className="rust_loader_instructions_item_title">
                                        <p>Problems with launcher?</p>
                                        <span>Read all instructions!</span>
                                    </div>
                                </div>
                                <div className="rust_loader_instructions_item_right">
                                    <Link className="rust_loader_instructions_item_button" to="/instructions">
                                        Read
                                    </Link>
                                </div>
                            </div>
                            <div className="rust_loader_instructions_item">
                                <div className="rust_loader_instructions_item_left">
                                    <div className="rust_loader_instructions_item_icon">
                                        <img src={loader_support} alt="" />
                                    </div>
                                    <div className="rust_loader_instructions_item_title">
                                        <p>Can't find what you're looking for?</p>
                                        <span>Chat our friendly support!</span>
                                    </div>
                                </div>
                                <div className="rust_loader_instructions_item_right">
                                    <Link className="rust_loader_instructions_item_button" to="/discord" target="_blank">
                                        Chat
                                    </Link>
                                </div>
                            </div>
                            {/* <span>Problems with launcher? <Link to="/instructions">Read all instructions!</Link></span>
                            <span>Can't find what you're looking for? <Link to="/discord" target="_blank">Chat our friendly support!</Link></span> */}
                        </div>
                    </div>
                    <div className="rust_loader_download">
                        <a onClick={() => download(loader, "Rust External.rar")} className="download_button">
                            <div className="download_button_icon">
                                <img src={loader_download} alt="" />
                            </div>
                            <span>Download</span>
                        </a>
                        <div className="rust_loader_download_info">
                            <span>Rust External.rar | {fileSize} MB</span>
                            <p className="rust_loader_download_password">
                                <div className="download_button_icon">
                                    <img src={alert_square} alt="" />
                                </div>
                                <span>Password to all archives: <code>radiance</code></span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}