import React from "react";
import SlideInAnimation from "../Components/SlideInAnimation";

import BuyRust from "../ComponentsRustExternal/BuyRust/BuyRust"
import HeroRust from "../ComponentsRustExternal/HeroRust/HeroRust";
import MediaRust from "../ComponentsRustExternal/MediaRust/MediaRust";
import FeaturesRust from "../ComponentsRustExternal/FeaturesRust/FeaturesRust";

export default function RustExternal() {

    let [rerenderBuy, setRerenderBuy] = React.useState(0);

    return (
        <div className="rustPage">
            <HeroRust />
            <MediaRust />
            <FeaturesRust rerenderValue={() => setRerenderBuy((rerenderBuy) => rerenderBuy + 1)} />
            <BuyRust newRender={rerenderBuy}/>
            <SlideInAnimation />
        </div>
    )
}